<template>
  <div>
    <v-card class="pa-4" max-width="520" min-height="300">
      <v-card-title class="panel-title"> Edit Sites </v-card-title>
      <v-card-text v-if="siteModel">
        <v-form
          ref="profileForm"
          v-model="valid"
          @submit.prevent="submit"
          action
        >
          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                autofocus
                label="Title"
                v-model="siteModel.title"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
              <v-text-field
                label="Subtitle"
                v-model="siteModel.subtitle"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
              <v-text-field
                label="Course ID"
                v-model="siteModel.courseId"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>
              <v-textarea
                label="Description"
                v-model="siteModel.description"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                rows="3"
                type="text"
              ></v-textarea>
              <v-textarea
                label="Landing Page"
                v-model="siteModel.landingPage"
                :rules="nameRules"
                :disabled="loading"
                outlined
                rows="3"
                type="text"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-textarea
                label="Thank you Page"
                v-model="siteModel.thankyouPage"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                rows="3"
                type="text"
              ></v-textarea>
              <v-text-field
                label="Price"
                v-model="siteModel.price"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>
              <v-file-input
                show-size
                truncate-length="18"
                :disabled="loading"
                :rules="photoRules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                label="Photo"
                outlined
                v-model="siteModel.photo"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4" class="mt-0">
              <v-btn
                :loading="loading"
                large
                color="primary"
                @click="submit"
                block
                >Save</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="mr-4 mt-0">
              <v-btn @click="$emit('close-dialog')" large outlined block
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row></v-row>
        <v-row class="mt-6 justify-end">
          <v-btn @click="$emit('close-dialog')" large raised color="primary"
            >Save</v-btn
          >
        </v-row> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
export default {
  props: ["game"],
  computed: {
    ...mapGetters(["formartCurrency", "formatDateMed"]),
  },
  created() {
    setTimeout(() => {
      console.log("lkkkkk==>", this.siteModel);
    }, 2000);
  },
  methods: {
    ...mapActions("api", ["httpGet", "httpPost", "httpPostMP"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },

    validate: function () {
      this.$refs.profileForm.validate();
    },
    saveSite: async function () {
      let formData = new FormData();
      formData.append("photoFile", this.siteModel.photo);
      formData.append("title", this.siteModel.title);
      formData.append("subtitle", this.siteModel.subtitle);
      formData.append("description", this.siteModel.description);
      formData.append("landingPage", this.siteModel.landingPage);
      formData.append("thankyouPage", this.siteModel.thankyouPage);
      formData.append("price", this.siteModel.price);
      formData.append("courseId", this.siteModel.courseId);
      if (this.siteModel._id) {
        formData.append("_id", this.siteModel._id);
      }

      console.log("posting===", formData);
      console.log(this.videoModel);
      let params = await this.httpPostMP({
        withAuth: true,
        url: "/admin/save_site",
        data: formData,
      });
      return params.data;
    },
    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Category name is requird",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            const resp = await this.saveSite();
            console.log("---we got--->", resp);
            this.loading = false;
            this.showMessage({ text: "Category saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            console.error(error);
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
  },
  data() {
    return {
      search: "",
      siteModel: {},
      menu: false,
      loading: false,
      busy: false,
      nameRules: [
        (v) => {
          if (v) {
            if (v.length > 2000000) {
              return "Name should be less than 30 characters";
            } else {
              return true;
            }
          } else {
            return "This Field is required";
          }
        },
      ],
      photoRules: [
        (v) => {
          if (v) {
            if (v.size < 2000000) {
              return true;
            } else {
              return "Photo size should be less than 2 MB!";
            }
          } else {
            return "This Field is required";
          }
        },
      ],
      valid: false,
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

